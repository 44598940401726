@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

*:focus {
  /* outline: none !important;
  box-shadow: none !important; */
}

.p-relative {
  position: relative;
}

.flex {
  display: flex;
}

.d-none {
  display: none;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.font-14 {
  font-size: 14px;
}

.font-20 {
  font-size: 20px;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 590;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.text-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-10 {
  opacity: 1;
}

.overflow-auto {
  overflow: auto;
}

.black-color {
  color: #000000;
}

.white-color {
  color: #ffffff;
}

.p-32-24 {
  padding: 32px 24px;
}

.p-32-34 {
  padding: 32px 34px;
}

.p-22-26 {
  padding: 22px 26px;
}

.pt-24 {
  padding-top: 24px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-38 {
  padding-bottom: 38px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-16 {
  padding-left: 16px;
}

.pl-08 {
  padding-left: 8px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-32 {
  margin-right: 32px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-08 {
  margin-left: 8px;
}

.persona-iframe {
  padding-bottom: 10px;
}

.persona-iframe iframe {
  width: 500px;
  height: 800px;
}

.draw-setup-sidebar {
  padding: 38px 10px;
  width: 50px;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 10;
}

@media only screen and (max-width: 1000px) {
  .persona-iframe iframe {
    width: 400px;
    height: 700px;
  }
}

@media only screen and (max-width: 500px) {
  .persona-iframe iframe {
    width: 300px;
    height: 700px;
  }
}

/* Welcome Dialog */
.welcome-dialog .MuiMobileStepper-dot {
  margin: 0 8px;
  width: 10px;
  height: 10px;
}

.welcome-dialog .MuiMobileStepper-dotActive {
  background-color: #000000;
}

/* Pagination */
.transaction-pagination .Mui-selected {
  background-color: #dbdee1 !important;
  color: #000000 !important;
}

/* React Phone Number Input 2 Style */
/* .react-tel-input {
  margin-bottom: 24px;
} */

.react-tel-input {
  border: 1px solid #cacaca;
  border-radius: 8px;
}

.react-tel-input .flag-dropdown {
  border-radius: 8px 0 0 8px !important;
  border-right: 1px solid #cacaca;
  border-left: 0px;
  border-bottom: 0px;
  border-top: 0px;
}

.react-tel-input .form-control {
  border: 0px;
  border-radius: 8px !important;
  height: 42px !important;
  width: 100% !important;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

.popover-content-width-same-as-its-trigger {
  width: var(--radix-popover-trigger-width);
  min-width: 300px;
  /* max-height: var(--radix-popover-content-available-height); */
}
